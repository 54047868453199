import React, { lazy, Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from "@/components/theme-provider";
import Navbar from '@/components/Navbar';

const Login = lazy(() => import('./components/Login'));
const SuperAdminDashboard = lazy(() => import('./components/SuperAdminDashboard'));
const AdminDashboard = lazy(() => import('./components/AdminDashboard'));
const QuoteDetail = lazy(() => import('./components/QuoteDetail'));

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const handleLogin = (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  };

  return (
    <ThemeProvider defaultTheme="light" storageKey="ui-theme">
      <Router>
        <Navbar />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route
              path="/login"
              element={<Login onLogin={handleLogin} />}
            />
            <Route
              path="/dashboard"
              element={
                user ? (
                  user.role === 'super_admin' ? (
                    <SuperAdminDashboard user={user} />
                  ) : (
                    <AdminDashboard user={user} />
                  )
                ) : (
                  <Login onLogin={handleLogin} />
                )
              }
            />
            <Route
              path="/factuur/:factuurId"
              element={
                user ? <QuoteDetail user={user} /> : <Login onLogin={handleLogin} />
              }
            />
            <Route path="/" element={<Login onLogin={handleLogin} />} />
          </Routes>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
}

export default App;